import * as React from 'react';
import { IWFacebookLikeProps } from '../WFacebookLike.types';
import {
  BASE_FACEBOOK_LIKE_API_URL,
  TRANSLATIONS_TITLE_NAMESPACE,
  TRANSLATIONS_TITLE_KEY,
  TRANSLATIONS_TITLE_DEFAULT,
} from './constants';
import styles from './styles/WFacebookLike.scss';

const getApiUrl = (params: Record<string, string>) =>
  `${BASE_FACEBOOK_LIKE_API_URL}?${new URLSearchParams(params)}`;

const WFacebookLike: React.FC<IWFacebookLikeProps> = ({
  id,
  translate,
  socialUrl,
  layout,
  showFaces,
  action,
  colorScheme,
  enableSend,
  locale,
  onMouseEnter,
  onMouseLeave,
}) => {
  const title = translate!(
    TRANSLATIONS_TITLE_NAMESPACE,
    TRANSLATIONS_TITLE_KEY,
    TRANSLATIONS_TITLE_DEFAULT,
  );

  const apiUrl = React.useMemo(
    () =>
      getApiUrl({
        href: socialUrl,
        layout,
        show_faces: showFaces.toString(),
        action,
        colorscheme: colorScheme,
        send: enableSend.toString(),
        locale,
      }),
    [socialUrl, layout, showFaces, action, colorScheme, enableSend, locale],
  );

  return (
    <wix-iframe
      id={id}
      class={styles.root}
      data-src={apiUrl}
      title={title}
      aria-label={title}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <iframe
        className={styles.iframe}
        data-src={apiUrl}
        title={title}
        aria-label={title}
        scrolling="no"
      />
    </wix-iframe>
  );
};

export default WFacebookLike;
